import React from "react"
import bookStyles from"./booksnippet.module.scss"

export default function ArticleSnippet(props) {
  
  return (
    <div className={bookStyles.book}>
        <p className={bookStyles.bookTitle}>Excerpt from <span role="img" aria-label="book" style={{fontSize:14}}>📖</span><br/><span>{props.bookTitle}</span></p>
        <div className={bookStyles.bookContent}>
            <p className={bookStyles.bookAbove}>{props.bookAbove}</p>
            <h1 className={bookStyles.bookText}>{props.bookText}</h1>
            <p className={bookStyles.bookBelow}>{props.bookBelow}</p>
            <p className={bookStyles.bookLink}><a href={props.bookLink} target="_blank" rel="noreferrer">See Book</a><span className={bookStyles.author}>by {props.author}</span><br/><span className={bookStyles.publisher}>{props.publisher}</span></p>
        </div>
    </div>
  )
}
import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Img from "gatsby-image"

import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/Footer'
import skillLayoutStyles from './skill-layout.module.scss'
import "@fontsource/montserrat"

import Tweet from '../article-components/Tweet'
import ArticleSnippet from "../article-components/ArticleSnippet"
import SkillList from "../components/SkillList"
import NextSection from "../article-components/NextSection"
import BookSnippet from "../article-components/BookSnippet"
import Quote from "../article-components/Quote"

const shortcodes = { Link, Quote, Tweet, ArticleSnippet, BookSnippet, SkillList, NextSection } // Provide common components here

const linkArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10px" height="13px" viewBox="0 -15 30 100" xmlSpace="preserve">
      <polyline fill="none" stroke="#5f6c80" strokeWidth={8} strokeLinecap="round" strokeLinejoin="round" points="
0.375,0.375 45.63,38.087 0.375,75.8 " />
    </svg>
);

export default function PageTemplate({ data: { mdx } }) {

  return (
      <div className={skillLayoutStyles.content}>
        <SEO
          title={mdx.frontmatter.title}
        />
        <Header/>
        <div className={skillLayoutStyles.container}>
        {mdx.frontmatter.featuredImage && <Img fluid={mdx.frontmatter.featuredImage.childImageSharp.fluid} /> }
          <div className={skillLayoutStyles.titleContainer}>
            {mdx.frontmatter.parent && 
              <Link className={skillLayoutStyles.backLink} to="../"><span>{linkArrow}{mdx.frontmatter.parent}</span></Link>
            }
            <h1 className={skillLayoutStyles.title}>{mdx.frontmatter.title}</h1>
            {mdx.frontmatter.subtitle && 
              <h3 className={skillLayoutStyles.subtitle}>{mdx.frontmatter.subtitle}</h3>
            }
            {mdx.frontmatter.qualifier && 
              <h3 className={skillLayoutStyles.qualifier}>{mdx.frontmatter.qualifier}</h3>
            }
            {mdx.frontmatter.skill &&
              <div className={skillLayoutStyles.supporting}>
                <p className={skillLayoutStyles.date}><span className={skillLayoutStyles.stage}>{mdx.frontmatter.stage}</span> Last updated on {mdx.frontmatter.date}</p>
              </div>
            }
            {mdx.frontmatter.type === "post" && 
              <p className={skillLayoutStyles.date}>Published on {mdx.frontmatter.date} <span className={skillLayoutStyles.timeToRead}>{mdx.timeToRead} min read</span></p>
            }
            {mdx.frontmatter.tags && 
              <p className={skillLayoutStyles.tags}>
                {mdx.frontmatter.tags.map((tag) => {
                const tagUrl = tag.replace(/(^...)[^]/g, '').replace(/\s+/g, '-').toLowerCase();
                const tager = tag.replace(/ /g, '\u00a0');
                  return(<Link to={tagUrl} key={tager}>{tager}</Link>)
                })}
              </p>
            }
          </div>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
      <Footer/>
      </div>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        parent
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 875) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
        qualifier
        skill
        tags
        date(formatString: "MMM Do, YYYY")
        type
        published
        stage
      }
    }
    
  }
`

import React from "react"
import nextStyles from './nextsection.module.scss'

export default function NextSection(props) {
  
  const linkArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="12px" height="16px" viewBox="0 -15 30 100" xmlSpace="preserve">
        <polyline fill="none" stroke="#00DB00" strokeWidth={15} strokeLinecap="round" strokeLinejoin="round" points="
	0.375,0.375 45.63,38.087 0.375,75.8 " />
      </svg>
  );

  return (
       <h5 className={nextStyles.next}>
           <a href={props.link}><span>{props.page}<span className={nextStyles.wiggle}>{linkArrow}</span></span></a>
       </h5>
  )
}
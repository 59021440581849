import React from "react"
import quoteStyles from './quote.module.scss'

export default function Quote(props) {
  

  return (
    <div className={quoteStyles.tweet}>
        <h1>{props.quoteText}</h1>    
        <p><a href={props.quoteLink} target="_blank" rel="noreferrer">{props.quoter}</a></p>
    </div>
  )
}